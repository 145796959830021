export default {
  methods:{
    async getAllDocumentsStates(documentFields, evaluator){
      let docs = [];
      let hasAssoc = false;
      let docAssoc = {};
      let url = this.apiStorageBasePrefix() + "files/getBulkDocumentFiles";
      documentFields.forEach(doc => {
        if(!doc.target_id){
          doc.loadedState = [];
          return;
        }
        
        hasAssoc = false;
        docAssoc = {};

        if(doc.formField.parsed_extras){
          let extras = doc.formField.parsed_extras;
          if(extras.doc_assoc){
            hasAssoc = true;
            docAssoc.assoc_table = extras.doc_assoc.table;
            docAssoc.assoc_key = extras.doc_assoc.assoc_key;
            docAssoc.assoc_id = evaluator(extras.doc_assoc.assoc_source);
          }
        }

        let def = {
          doc_id: doc.documentSet.id,
          target_id: doc.target_id,
          classification: doc.documentSet.clasification,
          has_assoc: hasAssoc,
          doc_assoc: docAssoc, 
        }
        docs.push(def);
      });

      try {
        let response = await this.publicApiPost(url, {bulk: docs});
        let res_docs = response.data;
        res_docs.forEach(rs => {
          let doc = documentFields.find(f => f.documentSet.id == rs.doc_id);
          
          let files = rs.files;
          let ll = 0;
          let file = null;
          let extras = doc.formField.parsed_extras;
          if(extras && extras.doc_assoc){
            hasAssoc = true;
          }

          if(hasAssoc && doc.multi_assoc_index != null){
            ll = doc.multi_assoc_index;
          }
          
          if(files && files.length > ll){
            file = files[ll];
          }

          doc.loadedState = file;
        });
        return true;
      }
      catch (error) {
        // this.warn(error);
        console.log(error);
        return false;
      }

    },
    async getDocumentState(target_id, doc_id, clasification, evaluator, f_extras, multi_assoc_index = null  ){
      if(!target_id){
        return null;
      }

      let url = this.apiStorageBasePrefix();
      let getUrl = null;
      let hasAssoc = false;
      let docAssoc = {};
      let assocData = null;

      if(f_extras){
        let extras = JSON.parse(f_extras);
        if(extras.doc_assoc){
          hasAssoc = true;
          docAssoc.table = extras.doc_assoc.table;
          docAssoc.assoc_key = extras.doc_assoc.assoc_key;
          docAssoc.condition = extras.doc_assoc.condition;
          docAssoc.assoc_source = extras.doc_assoc.assoc_source;
          docAssoc.neg_mssg = extras.doc_assoc.neg_mssg;
          docAssoc.multi_assoc = extras.doc_assoc.multi_assoc || false;

          assocData = evaluator(docAssoc.assoc_source);
        }
      }

      if(hasAssoc){
        // agregar información para asociacion
        getUrl = `${url}files/${doc_id}/getFilesByAssociated`;
        let params = `?assoc_table=${docAssoc.table}&assoc_key=${docAssoc.assoc_key}&assoc_id=${assocData}`;
        getUrl += params;
      }
      else {
        getUrl = this.isUserDoc(clasification) ? `${url}files/${doc_id}/user/${target_id}`
        : (this.isProjectDoc(clasification) ? `${url}files/${doc_id}/project/${target_id}` : null);

        if(!getUrl){ return null }
      }

      try {
        let res = await axios.get(getUrl);
        let files = res.data;
        let ll = 0;
        let file = null;

        if(hasAssoc && multi_assoc_index != null){
          ll = multi_assoc_index;
        }
        
        if(files && files.length > ll){
          file = files[ll];
        }

        return file;
      }
      catch (error) {
        return null;
      }
    },

    apiStorageBasePrefix(){
      return this.ApiDomain + "/storagev3/documents/";
    },
    isUserDoc(clasification){
      return clasification == 'user';
    },
    isProjectDoc(clasification){
      return clasification == 'project';
    }
  }
}