<template>
  <div class="vx-col w-full" v-if="isMounted">
    <div class="vx-row">
      <template v-for="(qf, index) in questionFields">
      <vs-divider v-if="checkQuestionVisibility(qf) && !qf.conditioned" :key="index"></vs-divider>
      <form-field 
          ref="form_fields"
          :key="qf.id"
          :f="qf.f"
          v-if="checkQuestionVisibility(qf)"
          :dataContainer="questionsContainer"
          :onChangeReceptor="onFieldChange"
          :evaluator="evaluateCondition" />

      </template>
    </div>
  </div>
</template>
<script>
import FormField from '@components/forms/FormField.vue'
const defClass = "vx-col lg:w-2/3 md:3/4 w-full mt-1 mb-2";

export default {
  name: 'technology_interview_form',
  props: {
    supplier_id: { type: [Number,String], required: true },
    family_group_id: { type: [Number,String], required: true },
    edition_disabled: { type: Boolean, required: true, default: false },
  },
  components: { FormField },
  data(){
    return {
      questionFields: [],
      questionsContainer: {},
      questionsList: [],
      supplierInterviewResponses: [],
      isMounted: false,

      conditionedQuestions: [],
    }
  },
  async mounted(){
    this.isMounted = false;
    this.showLoading(true);
    await this.getQuestions();
    await this.getSupplierResponses();
    this.setFormFields();
    this.isMounted = true;
    this.showLoading(false);
  },
  methods: {
    async getQuestions(){
      try {
        
        let res1 = await axios.get(`/api/v1/fg_survey/family_group/${this.$props.family_group_id}/getSupplierInterviewQuestions`);
        this.questionsList = res1.data;
      }
      catch (error) {
        this.warn(error);
      }
    },
    async getSupplierResponses(){
      try {
        let res = await axios.get(`/api/v1/fg_survey/family_group/${this.$props.family_group_id}/supplier/${this.$props.supplier_id}/getSupplierInterviewResponses`);
        this.supplierInterviewResponses = res.data;
        this.supplierInterviewResponses.forEach(response => {
          let fg_name = 'q_' + response.fg_survey_question_id;
          let r = response.value;
          if(response.question_type == "combo"){
            r = parseInt(r);
          }
          this.questionsContainer[fg_name] = r;
        });
      } catch (error) {
        this.warn(error);
      }
    },
    setFormFields(){     
      // condicionales para preguntas condicionadas
      this.questionsList.forEach(question => {
        question.responses.forEach(res => {
          if(res.extra_question_id != null){
            this.conditionedQuestions.push({id: res.extra_question_id, condition_id: question.id, condition_value: res.value, state: false})
          }
        })
      });

      this.questionsList.forEach(question => {
        let q = this.conditionedQuestions.find(f => f.id == question.id);
        let qq;
        if(q){
          qq = this.formFieldForQuestion(question, true, q.id);
        }
        else {
          qq = this.formFieldForQuestion(question, false, null);
        }

        let cond = this.conditionedQuestions.find(f => f.condition_id == question.id);
        if(cond){
          qq.f.on_change_method = "on_question_changed";
        }
        
        this.questionFields.push(qq);
      });

      this.on_question_changed();
    },
    formFieldForQuestion(question, conditioned, condition_id){
      let fname = 'q_' + question.id;
      let ff = {
        id: question.id,
        field_name: question.question,
        fname: fname,
        order: question.order,
        db_field: fname,
        db_table: "fg_survey_questions",
        field_type: this.translateDataTypes(question),
        is_required: true,
        collection: this.getCollectionValues(question),
        bo_private: 0,
        div_class: conditioned ? defClass + " mt-2" : defClass,
        special_definition: 0,
        show_conditioned: false,
      };
      if(this.$props.edition_disabled){
        ff.disabled = true;
      }

      if(ff.field_type == "date"){
        ff.extras = "{'disabled_from_today': true}";
        ff.parsed_extras = {disabled_from_today:true};
        ff.disabled_from_today = true;
      }

      let qf = {f: ff, conditioned: conditioned, condition_id: condition_id};

      // agregar un evento de cambio para actualizar la pregunta hija
      return qf;
    },
    translateDataTypes(fg_survey_question){
      if(fg_survey_question.data_type == 'combo'){
        return 'select'
      } else if(fg_survey_question.data_type == 'date'){
        return 'date';
      } else if(fg_survey_question.data_type == 'number'){
        return 'number';
      } else if(fg_survey_question.data_type == 'textarea'){
        return 'textarea';
      }
      return "text";
    },
    getCollectionValues(fg_survey_question){
      if(fg_survey_question.data_type == 'combo'){
        let options = [];
        fg_survey_question.responses.forEach(res => {
          options.push({k: res.value, v: res.response});
        })
        let collection = { source: 'self_options', options: options};
        return  JSON.stringify(collection);
      }
      return null;
    },
    async checkValidation(){
      let allValid = true;
      let grefs = this.$refs['form_fields'];
      let f;
      for(let p in grefs){
        f = grefs[p];
        let r = await f.checkForValidDirty();
        if(r.valid == false){
          allValid = false;
        }
      }
      return allValid;
    },
    setError(mssg){
      this.specialError = mssg;
    },
    specialValidation(){      
      return this.f.special_validation;
    },

    // llamado por el objeto padre
    async saveInterview(accreditation_id = -1){
      let reqs = [];
      this.questionsList.forEach(fg => {
        let fg_name = 'q_' + fg.id;
        let item = {fg_survey_question_id: fg.id, value: this.questionsContainer[fg_name]};
        let ff = this.supplierInterviewResponses.find(f => f.fg_survey_question_id == fg.id);
        if(ff){
          item.id = ff.id;
        }
        reqs.push(item);
      });

      let payload = {
        supplier_fg_interview_responses: reqs,
        accreditation_id: accreditation_id
        };
      try {
        await axios.post(`/api/v1/fg_survey/supplier/${this.$props.supplier_id}/saveSupplierInterviewResponses`, payload);
        return "ok";
      }
      catch (error) {
        this.warn(error);
        return "error";
      }
    },

    /* evaluator */
    evaluateCondition(condition){   
    },
    
    checkQuestionVisibility(qf){
      if(!qf.conditioned){
        return true;
      }
      return this.conditionedQuestions.find(f => f.id == qf.condition_id).state;
    },

    on_question_changed(){
      // loop en las preguntas para identificar cambios
      this.conditionedQuestions.forEach(q => {
        let v = this.questionsContainer['q_' + q.condition_id];
        q.state = (q.condition_value == v);
      });
    },

    /* on change receptor */
    onFieldChange(method){
      if(method != null && method in this){
        this[method]();
      }
    },
  }
}
</script>