<template>
  <div class="vx-col w-full mb-5" >
    <vs-button v-if="!edition_disabled" color="primary" type="border" icon-pack="feather" class="mb-4"  @click="addLocationTestimony()">Agregar testimonio</vs-button>      
        
      <vs-table stripe noDataText="No se han generado registros" :data="testimonies">
        <template slot="thead">
          <vs-th class="bold">NOMBRE</vs-th>
          <vs-th class="bold">TELÉFONO</vs-th>
          <vs-th class="bold">ESTADO</vs-th>
          <vs-th class="bold">COMENTARIOS</vs-th>
          <vs-th class="bold" v-if="!edition_disabled || hasBackofficeAccess">ACCIONES</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="data[indextr].complete_name">
              {{ data[indextr].complete_name }}
            </vs-td>

            <vs-td v-if="data[indextr].phone" :data="data[indextr].phone_id">
              {{ data[indextr].phone.country_calling_code }} {{ data[indextr].phone.phone }}
            </vs-td>
            <vs-td v-else :data="data[indextr].phone">
              --
            </vs-td>
            
            <vs-td :data="testimonyStatus(data[indextr].testimony_verification) + '_' + indextr">
              <vs-chip :color="chipColor(data[indextr].testimony_verification)">
                {{ testimonyStatus(data[indextr].testimony_verification) }}
              </vs-chip>
            </vs-td>

            <vs-td :data="data[indextr].id + '_comment'">
              {{ getComments(data[indextr].testimony_verification) }}
            </vs-td>

            <vs-td :data="data[indextr].id"  v-if="!edition_disabled">
              <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" class="ml-6" @click.stop="removeItemRequest(data[indextr])" />
            </vs-td>
            <vs-td :data="data[indextr].id"  v-else-if="hasBackofficeAccess">
              <feather-icon v-if="showActions(data[indextr].testimony_verification)" :icon="family_group_id != 1 ? 'UserCheckIcon' : 'MessageSquareIcon'" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" class="ml-6" @click.stop="openQuestionary(data[indextr])" />
              <feather-icon v-if="showActions(data[indextr].testimony_verification)" icon="UserXIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" class="ml-6" @click.stop="rejectItemRequest(data[indextr])" />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <!-- MODAL AGREGAR TESTIMONIO -->
      <vs-popup
        id="add-testimonie"
        title="Agregar testimonio"
        :active.sync="showFormPrompt">
        <div class="con-exemple-prompt">
          <vs-alert v-if="onError" class="h-full mb-5" color="danger">
            <span>{{ errorMssg }}</span>
          </vs-alert>
          <vs-alert v-if="onSuccess" class="h-full mb-5" color="success">
            <span>{{ successMssg }}</span>
          </vs-alert>

          <p class="mb-5 bold">{{formPromptIndication}}</p>

          <vs-input
            class="w-full mb-4" 
            label="Nombre del testimonio"
            placeholder="Requerido"
            v-validate="'required'"
            v-on="{input: e => {complete_name = upper(e) }}"
            data-vv-name="complete_name"
            :danger="hasError('complete_name')"
            :danger-text="errorText('complete_name')"
            :success="isSuccess('complete_name')"
            v-model.lazy="complete_name" />

            <div class="flex">
              <vue-country-code
                name="dialCode"
                class="vs-custom dial-code"
                v-validate="'required'"
                data-vv-name="phone"
                :enableSearchField="true"
                searchPlaceholderText="Buscar código de país"
                :enabledCountryCode="true"
                @onSelect="onSelect"
                :defaultCountry="preferredCountries"
                :preferredCountries="['MX']"
                :danger="hasError('dialCode')"
                :danger-text="errorText('dialCode')"
                :success="isSuccess('dialCode')">
              </vue-country-code>
  
              <vs-input
              class="w-full mb-4" 
              type="tel"
              label="Teléfono del testimonio"
              placeholder="Requerido"
              v-validate="'required|digits:10'"
              data-vv-name="phone"
              icon-pack="feather"
              icon="icon-phone"
              :danger="hasError('phone')"
              :danger-text="errorText('phone')"
              :success="isSuccess('phone')"
              v-model.lazy="phone" />  
            </div>


          <vs-button :disabled="!completeForm || $isLoading" @click="saveTestimony" color="primary" class="mr-5 mt-2">Agregar</vs-button>
          <vs-button @click="closeFormPrompt" :disabled="$isLoading" type="flat" color="primary">Cerrar</vs-button>
            
        </div>
      </vs-popup>

      <!-- MODAL ENTREVISTA TESTIMONIO -->
      <vs-popup v-if="hasBackofficeAccess"
        :title="  family_group_id != 1 ? `Verificación de testimonio ${selectedTestimony.complete_name}` : `Cuestionario para el testimonio: ${selectedTestimony.complete_name}`"
        :active.sync="showFormPrompt2">
        <div class="con-exemple-prompt">
          <vs-alert v-if="onError" class="h-full mb-5" color="danger">
            <span>{{ errorMssgIndication }}</span>
          </vs-alert>
          <vs-alert v-if="onSuccess" class="h-full mb-5" color="success">
            <span>{{ successMssgIndication }}</span>
          </vs-alert>

          <p class="mb-5 bold">{{ family_group_id != 1 ? questionaryIndicationOther : questionaryIndication }}</p>

          <div class="vx-row">
            <testimony-interview-form
            ref="testimony_interview"
            :supplier_id="supplier_id"
            :edition_disabled="false"
            :family_group_id="family_group_id"
            :supplier_fg_testimony_id="selectedTestimony.id" />
          </div>


          <vs-button @click="saveInterview" color="primary" class="mr-5 mt-2">{{ family_group_id != 1? "VERIFICAR" : "AGREGAR"}}</vs-button>
          <vs-button @click="closeQuestionary" :disabled="$isLoading" type="flat" color="primary">CERRAR</vs-button>
            
        </div>
      </vs-popup>

      <!-- MODAL RECHAZAR TESTIMONIO -->
      <vs-popup
        v-if="hasBackofficeAccess"
        title="Rechazar testimonio"
        :active.sync="showFormPrompt3">
        <div class="con-exemple-prompt">
          <p class="mb-5 bold">Escribe las razones por las cuales estás rechazando el testimonio. </p>

            <vx-textarea
              class="w-full"
              id="comments"
              v-model.lazy="comments"
              name="comments"
              :danger="hasError('comments')"
              :danger-text="errorText('comments')"
              :success="isSuccess('comments')"
            />

          <vs-button :disabled="!completeFormReject" @click="doRejectItem" color="danger" class="mr-5 mt-2">Rechazar</vs-button>
          <vs-button @click="closeRejectPrompt" type="flat" color="primary">Cerrar</vs-button>
            
        </div>
      </vs-popup>

    </div>

</template>

<script>
import inputHelper from "@mixins/inputHelper"
import TestimonyInterviewForm from '@technologies/TestimonyInterviewForm.vue'

const indicationDef = "Los datos de este testimonio deben corresponder con alguno de tus clientes que te haya comprado la tecnología a verificar.";
const errorMssgDef = "Ha ocurrido un error al agregar al testimonio, intente más tarde."
const successMssgDef = "El testimonio ha sido agregado exitosamente"
const statePresenceVal = 0;
const cityPresenceVal = 1;

const questionaryIndication = "Responde a las preguntas con base a la información que solicites al testimonio. Este cuestionario sólo aplicará al testimonio seleccionado."
const successMssgIndication = "Cuestionario guardado de manera correcta."
const errorMssgIndication = "Ha ocurrido un error al agregar el cuestionario. Intente más tarde."

const questionaryIndicationOther = "Por el momento no contamos con una entrevista para este tipo de tecnología, para verificar sólo da clic en el botón <VERIFICAR>";

export default {
  name: 'supplier-testimonies-control',
  mixins: [inputHelper],
  props: {
    family_group_id: { type: [String,Number], required: true },
    supplier_id: { type: [Number,String], required: true },
    edition_disabled: { type: Boolean, required: true, default: false },
  },
  components : {
    TestimonyInterviewForm,
  },
  data(){
    return {
      complete_name: null,
      phone: null,
      dialCode: null,
      preferredCountries: 'MX',

      getGroupsForSelect: [],
      testimonies: [],

      showFormPrompt: false,
      selectedTestimony: {
        selectedTestimony: null,
        phone: null,
        id: ""
      },

      onError: false,
      onSuccess: false,
      errorMssg: errorMssgDef,
      successMssg: successMssgDef,
      formPromptIndication: indicationDef,

      // CUESTIONARIO A TESTIMONIOS
      showFormPrompt2: false,
      questionaryIndication: questionaryIndication, 
      questionaryIndicationOther: questionaryIndicationOther, 
      successMssgIndication: successMssgIndication, 
      errorMssgIndication: errorMssgIndication, 
      showFormPrompt3: false,
      comments: "",
    }
  },
  mounted: async function(){
    await this.loadSavedTestimonies();
  },
  computed: {
    completeForm(){
      return this.family_group_id != null && this.complete_name != null && this.phone != null && this.phone.length == 10;
    }, 
    completeFormReject(){
      return this.comments != null && this.comments != "";
    }, 
    hasBackofficeAccess(){
      return this.$acl.check('isBackoffice');
    },
  },
  methods: {
    onSelect({ dialCode }) {
      this.dialCode = `+${dialCode}`;
    },
    async loadSavedTestimonies(){
      try {
        let params = "?with[]=supplierFgTestimonies&with[]=supplierFgTestimonies.testimonyVerification&with[]=supplierFgTestimonies.phone&&filter[familyGroups.id]=" + this.family_group_id;
        let response = await axios.get(`/api/v1/supplier/${this.$props.supplier_id}${params}`);
        this.testimonies = response.data.supplier_fg_testimonies.filter(f => f.family_group_id == this.family_group_id);   
      }
      catch (e) {
        this.warn(e);
      }      
    },
    removeItemRequest(testimony){
      this.selectedTestimony = testimony;

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Eliminar testimonio',
        text: `¿Realmente desea eliminar el testimonio con nombre ${testimony.complete_name}?`,
        acceptText: "Eliminar",
        cancelText: 'Cancelar',
        accept: this.doRemoveItem
      });
    },
    async doRemoveItem(){
      this.showLoading(true);
      try {
        let payload = {testimony_id: this.selectedTestimony.id};
        await axios.post('/api/v1/supplier/removeSupplierTestimony', payload);
        await this.loadSavedTestimonies();
      }
      catch (error) {
        this.warn(error);
      }
      this.showLoading(false);
    },
    async saveTestimony(){
      this.onSuccess = false;
      this.onError = false;
      
      this.showLoading(true);
      try {        
        let payload = { 
          complete_name: this.complete_name
          , phone: this.phone
          , family_group_id: this.family_group_id
          , country_calling_code: this.dialCode
        };
        await axios.post(`/api/v1/supplier/${this.$props.supplier_id}/addSupplierTestimony`, payload);
        this.onSuccess = true;
        await this.loadSavedTestimonies();
        this.closeFormPrompt();
      }
      catch (error) {
        this.warn(error);
        this.onError = true;
      }
      this.showLoading(false);
    },
    addLocationTestimony(){
      this.resetForm();
      this.showFormPrompt = true;
    },
    resetForm(){
      this.complete_name = null
      this.phone = null
      this.onError = null
      this.onSuccess = null
    },
    closeFormPrompt() {
      this.showFormPrompt = false;
      this.resetForm();
    },
    hasError(val){
      return this.errors.has(val);
    },
    errorText(val){
      return this.errors.first(val);
    },
    isSuccess(val){
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);      
    },

    /* check validation */
    validate(){
      return this.testimonies.length >= 3;
    },

    // #region Cuestionartio a testimonios.
    async openQuestionary(testimony){
      this.selectedTestimony = testimony;
      await this.$refs.testimony_interview.getTestimonyInterviewTrigger();
      this.showFormPrompt2 = true;
    },
    async closeQuestionary() {
      this.showFormPrompt2 = false;
      await this.$refs.testimony_interview.closeTestimonyInterviewTrigger();
      this.selectedTestimony = {
        selectedTestimony: null,
        phone: null,
        id: ""
      };
      this.onSuccess = false;
      this.onError = false;
    },

    async saveInterview(){
      let r = await this.$refs.testimony_interview.checkValidation();
      if(!r){
        this.missingFieldsNotif();
        return;
      }

      this.showLoading(true);
      let res = await this.$refs.testimony_interview.saveInterview();
      if(res == "ok"){
        this.saveSuccessNotif();
        this.onSuccess = true;
        this.loadSavedTestimonies();
      }
      else {
        this.failedOperationNotif(null, null);
        this.onError = true;
      }
      this.showLoading(false);
    },
    rejectItemRequest(testimony){
      this.selectedTestimony = testimony;
      this.showFormPrompt3 = true;
    },

    async doRejectItem(){
      this.showLoading(true);
      try {
        let payload = {comments: this.comments};
        await axios.post(`/api/v1/fg_survey/testimony/${this.selectedTestimony.id}/rejectTestimony`, payload);
        await this.loadSavedTestimonies();
        this.closeRejectPrompt();
      }
      catch (error) {
        this.warn(error);
      }
      this.showLoading(false);
    },
    closeRejectPrompt(){
      this.showFormPrompt3 = false;
      this.comment = "";
    },
    testimonyStatus(verification){
      if(verification != null){
        return verification.is_verified.toUpperCase();
      } 
      return "--";
    },
    chipColor(verification){
      if(verification != null){
        switch(verification.is_verified){
          case "sin verificar":
            return "primary";
          case "verificado": 
            return "success";
          case "rechazado":
            return "danger";
          default: return "#b8c2cc";
       }
      }
      return "#b8c2cc";
    },
    showActions(verification){
      if(verification != null){
          return verification.is_verified == "sin verificar" ? true : false;
      } else {
        return false;
      }
    },
    getComments(verification){
      if(verification != null){
        return verification.comments != null && verification.comments != "" ? verification.comments : "SIN COMENTARIOS";
      }
    }
    // #endregion
  }
}
</script>

<style>

#add-testimonie {
  z-index: 900000 !important;
}
</style>