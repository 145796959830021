<template>
  <div :class="{'p-6': hasBackofficeAccess}">
    <div class="vx-row" v-if="hasBackofficeAccess">
      <div class="vx-col w-full mb-3">
        <router-link
          class="decorated"
          :to="{name: 'Instalador', params: {id: real_supplier_id}}"
        >Regresar a perfil de proveedor </router-link>
      </div>
    </div>

    <big-title v-if="hasBackofficeAccess">
      Tecnología - <span>{{name}}</span>

      <template v-if="accreditationStatus == 0">
        <vs-alert icon-pack="feather" icon="icon-alert-triangle" class="h-full mt-4" color="dark">
          <span class="font-regular">La solicitud del proveedor está en proceso de ser completada.</span>
        </vs-alert>
      </template>

      <template v-else-if="accreditationStatus == 1">
        <vs-alert icon-pack="feather" icon="icon-alert-triangle" class="h-full mt-4" color="warning">
          <span class="font-regular">La solicitud de acreditación está <strong>en proceso de revisión</strong>.</span>
        </vs-alert>
        <div class="vx-row">
          <div class="vx-col w-full mt-4 flex" id="account-manage-buttons">
            <vs-button class="mr-6" color="primary" @click="acceptAccreditation">Validar solicitud</vs-button>
            <vs-button class="ml-auto" color="danger" @click="rejectAccreditation">Rechazar solicitud</vs-button>
          </div>
          <div class="vx-col w-full mt-6 flex" id="account-manage-buttons">
            <vs-button color="warning" @click="resetAccreditation">Regresar solicitud a en proceso</vs-button>
          </div>
        </div>
      </template>

      <template v-else-if="accreditationStatus == 2">
        <vs-alert icon-pack="feather" icon="icon-alert-triangle" class="h-full mt-4" color="success">
          <span class="font-regular">El proveedor ya se encuentra <strong>acreditado</strong> en esta tecnología.</span>
        </vs-alert>
      </template>

      <template v-else-if="accreditationStatus == 3">
        <vs-alert icon-pack="feather" icon="icon-alert-triangle" class="h-full mt-4" color="danger">
          <span class="font-regular">La solicitud de acreditación ha sido <strong>rechazada</strong>.</span>
        </vs-alert>
        <p v-if="remainingDays > 0" class="text-subtitle mt-4">
          <strong> Fecha en que se podrá solicitar de nueva cuenta la acreditación:</strong> {{nextAccreditDate}} .<br>
        </p>
        <vs-alert v-if="remainingDays <= 0" icon-pack="feather" icon="icon-alert-triangle" class="h-full mt-4" color="warning">
          <span class="font-regular">El proveedor ya puede solicitar de nueva cuenta la acreditación.
            El proveedor mismo podrá habilitar de nuevo la solicitud dando click en el botón "Habilitar solicitud" que verá en su interfaz.
            De igual manera se puede habilitar por el dando click en el siguiente botón.</span>
        </vs-alert>
        <div v-if="remainingDays <= 0" class="vx-col w-full mt-4 flex" id="account-manage-buttons">
          <vs-button class="mr-4" type="border" color="warning" @click="requestEditAccreditation">Habilitar solicitud para proveedor</vs-button>
        </div>
      </template>
    </big-title>

    <big-title v-else>
      Solicitud de acreditación en tecnología - <span>{{name}}</span>

      <template v-if="accreditationStatus == 0">
        <vs-alert icon-pack="feather" icon="icon-alert-triangle" class="h-full mt-4" color="danger">
          <span class="font-regular">Tu solicitud está en proceso de ser completada.
          Cuando estés listo, da click en el botón "Enviar solicitud" para enviarla a revisión.</span> Toma en consideración que en caso de que tu solicitud sea rechazada, deberán pasar 6 meses para que puedas
          aplicar de nueva cuenta a la misma tecnología.
        </vs-alert>
        <div class="vx-col w-full mt-4 flex" id="account-manage-buttons">
          <vs-button class="mr-4" @click="requestAccreditation">Enviar solicitud</vs-button>
        </div>
      </template>

      <template v-else-if="accreditationStatus == 1">
        <vs-alert icon-pack="feather" icon="icon-alert-triangle" class="h-full mt-4" color="warning">
          <span class="font-regular">Tu solicitud de acreditación está <strong>en proceso de revisión</strong>.
          En breve se dará respuesta.</span>
        </vs-alert>
      </template>

      <template v-else-if="accreditationStatus == 2">
        <vs-alert icon-pack="feather" icon="icon-alert-triangle" class="h-full mt-4" color="success">
          <span class="font-regular">Ya te encuentras <strong>acreditado</strong> en esta tecnología.</span>
        </vs-alert>
      </template>

      <template v-else-if="accreditationStatus == 3 && remainingDays > 0">
        <vs-alert icon-pack="feather" icon="icon-alert-triangle" class="h-full mt-4" color="danger">
          <span class="font-regular">Lamentablemente tu solicitud de acreditación ha sido <strong>rechazada</strong>.
          Deberán transcurrir 6 meses desde la fecha de solicitud antes de que puedas volver a enviar una solicitud para esta tecnología.
          Si tienes dudas al respecto, por favor comunícate via Chat.</span>
        </vs-alert>

        <p class="text-subtitle mt-4">
          <strong> Fecha en que podrás solicitar de nueva cuenta la acreditación:</strong> {{nextAccreditDate}} .<br>
        </p>
      </template>

      <template v-else-if="accreditationStatus == 3 && remainingDays <= 0">
        <vs-alert icon-pack="feather" icon="icon-alert-triangle" class="h-full mt-4" color="warning">
          <span class="font-regular">Tu solicitud de acreditación se encuentra <strong>rechazada</strong>.
          Sin embargo, puedes realizar el proceso de nueva cuenta, dando click en el botón "Habilitar solicitud".</span>
        </vs-alert>

        <div class="vx-col w-full mt-4 flex" id="account-manage-buttons">
          <vs-button class="mr-4" type="border" @click="requestPostRejectionEdit">Habilitar solicitud</vs-button>
        </div>
      </template>

    </big-title>

    <vx-card v-if="isMounted && !failed">
      <div slot="no-body" class="tabs-container px-6 pt-6">

        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-container">
          <vs-tab :label="docsMissing.length > 0 ? 'Acreditaciones del negocio ( 1 )' : 'Acreditaciones del negocio'" icon-pack="feather" icon="icon-award">
            <div class="tab-text">
              <vs-alert v-if="docsMissing.length > 0" icon-pack="feather" style="height:auto" icon="icon-alert-triangle" class="mt-1 mb-3" color="danger">
                  <span class="font-regular">Verifique los siguientes campos faltantes:<br>
                    <span v-for="(miss, i) in docsMissing" :key="i">
                      <strong>- {{miss.n}}: </strong> {{miss.e}}<br>
                    </span>
                  </span>
              </vs-alert>
              <vx-card :title="accreditSection.data.public_name" class="mb-5">
                <p class="mb-4" v-if="accreditSection.data.public_description != null">{{accreditSection.data.public_description}}</p>

                <div class="vx-row">
                  <template v-for="f in accreditSection.data.fields">

                    <!-- Campos automaticos -->
                    <form-field
                      ref="accreditations"
                      v-if="isAutoFormField(f)"
                      :key="f.id"
                      :f="f"
                      :dataContainer="accreditations"
                      :collectionsRoot="collections"
                      :onChangeReceptor="onFieldChange"
                      :evaluator="evaluateCondition" />

                  </template>
                </div>

                <template v-if="accreditSection.documentFields.length > 0">
                  <div class="vx-row">
                    <document-upload
                      v-for="df in accreditSection.documentFields"
                      :key="df.id"
                      :target_id="df.target_id"
                      :f="df.formField"
                      :doc="df.documentSet"
                      :loadedState="df.loadedState"
                      :disabled="docsDisabled"
                      :evaluator="evaluateCondition"
                      :editableByBackoffice="true"
                      @on-success="onDocumentUploadSuccess"
                    />
                  </div>
                </template>
              </vx-card>

            </div>
          </vs-tab>

          <vs-tab :label="testsMissing.length > 0 ? 'Testimonios ( 1 )' : 'Testimonios'" icon-pack="feather" icon="icon-users">
            <div class="tab-text">

              <vs-alert v-if="testsMissing.length > 0" icon-pack="feather" style="height:auto" icon="icon-alert-triangle" class="mt-1 mb-3" color="danger">
                  <span class="font-regular">Verifique los siguientes campos faltantes:<br>
                    <span v-for="(miss, i) in testsMissing" :key="i">
                      <strong>- {{miss.n}}: </strong> {{miss.e}}<br>
                    </span>
                  </span>
              </vs-alert>

              <vx-card title="Testimonios de clientes" class="mb-5">
                <p class="mb-4">Es necesario ingresar al menos tres testimonios</p>
                <supplier-testimonies-control
                  ref="supplier_testimonies_component"
                  :supplier_id="real_supplier_id"
                  :edition_disabled="generalDisabled"
                  :family_group_id="family_group_id" />

              </vx-card>

            </div>
          </vs-tab>

          <vs-tab :label="questMissing.length > 0 ? 'Cuestionario ( 1 )' : 'Cuestionario'" icon-pack="feather" icon="icon-list">

            <vs-alert v-if="questMissing.length > 0" icon-pack="feather" style="height:auto" icon="icon-alert-triangle" class="mt-1 mb-3" color="danger">
                  <span class="font-regular">Verifique los siguientes campos faltantes:<br>
                    <span v-for="(miss, i) in questMissing" :key="i">
                      <strong>- {{miss.n}}: </strong> {{miss.e}}<br>
                    </span>
                  </span>
            </vs-alert>

            <div class="tab-text">
              <vx-card :title="interviewSection.data.public_name" class="mb-5">
                <p class="mb-4" v-if="interviewSection.data.public_description != null">{{interviewSection.data.public_description}}</p>

                <div class="vx-row">
                  <technology-interview-form
                    ref="interview"
                    :supplier_id="real_supplier_id"
                    :edition_disabled="generalDisabled"
                    :family_group_id="family_group_id" />
                </div>
              </vx-card>

              <!-- Save Button -->
              <div class="vx-row" v-if="!generalDisabled">
                <div class="vx-col w-full">
                  <div class="flex flex-wrap items-center justify-end">
                    <vs-button class="ml-auto mt-2" @click="saveInterview">Guardar cambios</vs-button>
                  </div>
                </div>
              </div>

            </div>
          </vs-tab>

        </vs-tabs>

      </div>
    </vx-card>

    <load-error v-if="failed" />
  </div>
</template>

<script>
import inputHelper from '@mixins/inputHelper';
import dateHelper from '@mixins/dateHelper';
import docsHelper from '@mixins/docsHelper';
import formHelper from '@mixins/formHelper';
import FormField from '@components/forms/FormField.vue'
import SupplierTestimoniesControl from '@supplier/SupplierTestimoniesControl.vue'
import TechnologyInterviewForm from '@technologies/TechnologyInterviewForm.vue'

const tabsDefs = [
  { slug: "accreditations", title: "Acreditaciones del negocio",  icon: "icon-briefcase", sections:[7] },
  { slug: "references", title: "Referencias de clientes", icon: "icon-users", sections:[8] },
  { slug: "interview", title: "Entrevista", icon: "icon-map-pin", sections:[48] },
];
const verifiedEnumValue = "verificado";
const rejectedEnumValue = "rechazado";
export default {
  name: 'accreditation-detail',
  props: ['id', 'supplier_id'],
  mixins: [formHelper, inputHelper, dateHelper, docsHelper],
  components: {
    FormField,
    SupplierTestimoniesControl,
    TechnologyInterviewForm,
  },
  data(){
    return {
      isMounted: false,
      failed: false,
      activeTab: 0,

      allSections: [],
      allDocumentStates: [],

      accreditations: {},
      accreditSection: {},

      interview: {},
      interviewSection: {},

      collections: {},

      formIsDisabled: false,

      family_group_id: null,
      real_supplier_id: null,
      user_id: null,

      sectionGroups: [],
      documentsStates: [],
      allDocumentFields: [],

      accreditationStatus: null,
      isAccredited: false,
      isRejected: false,

      nextAccreditDate: null,
      remainingDays: null,
      docsMissing: [],
      testsMissing: [],
      questMissing: [],

      totalInvalidFields: 0,

      base: {},
      name: null,
    }
  },
  async mounted() {
    this.showLoading(true);
    this.isMounted = false;
    this.setSupplier();
    await this.getSupplieFGData();
    await this.getFormInfoAccredit();
    await this.getFormInfoInterview();
    this.isMounted = true;
    this.showLoading(false);
  },
  computed: {
    supplier_family_group_exists(){
      return true;
    },
    supplier_family_group_id(){
      return this.base.id;
    },
    hasBackofficeAccess(){
      return this.$acl.check('isBackoffice');
    },
    generalDisabled(){
      return !this.updateRequired ? (this.formIsDisabled || this.hasBackofficeAccess) : false;
    },
    docsDisabled(){
      return !this.updateRequired ? this.formIsDisabled : false;
    },
    updateRequired(){
      return this.SupplierStatus >= 4 
      && this.SupplierUpdateProfileInfo.update_profile_requirement === 1 
      && this.SupplierUpdateProfileInfo.update_profile_completed_for_review_date === null
    }
  },
  methods: {
    setSupplier(){
      if (process.env.VUE_APP_IS_BACKOFFICE == "true"){
        this.real_supplier_id = this.$props.supplier_id;
      }
      else {
        this.real_supplier_id = this.SupplierId;
      }
    },
    setBreadcrumb(){
      this.$emit('setActiveBreadcrumbTitle', this.name);
    },
    async getSupplieFGData(){
      try{
        // ?filter[status]=3 // ejemplo de filtro
        let params = "?with[]=supplierFamilyGroups.familyGroup&with[]=supplierFamilyGroups.documentFiles&with[]=user";
        let response = await axios.get(`/api/v1/supplier/${this.real_supplier_id}${params}`);
        let groups = response.data.supplier_family_groups;
        let sg = groups.find(f => f.id == this.id);
        this.base = sg;
        this.user_id = response.data.user.id;
        this.family_group_id = sg.family_group_id;
        this.name = sg.family_group.name;
        this.setBreadcrumb();
        this.accreditationStatus = sg.accreditation_status;
        this.formIsDisabled = this.accreditationStatus > 0;

        if(this.accreditationStatus == 3){
          this.nextAccreditDate = this.nextAccreditationDate(sg.accreditation_request_date);
          let passed = this.calculateDaysDiff(sg.accreditation_request_date);
          this.remainingDays = 180 - passed;
        }
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    async getFormInfoAccredit(){
      try{
        let res1 = await axios.get("/api/v1/forms/getSectionFields/7");
        let resd1 = await axios.get("/storagev3/documents/section/7");
        let docStates = resd1.data;
        let acc = {data: res1.data.section, documentFields: []};
        this.allDocumentFields = [];

        await this.asyncForEach(acc.data.fields, async (f) => {
          this.formFieldSetter(f, this);
          // check for documents
          if(f.doc_id != null){
            let ff = {formField: f, documentSet: null, target_id: null };
            if(docStates.length > 0){
              let dset = docStates.find(d => d.id == f.doc_id);
              ff.target_id = this.docTargetEvaluator(dset.clasification, dset.clasification_2);
              ff.documentSet = dset;
            }
            acc.documentFields.push(ff);
            this.allDocumentFields.push(ff);
          }
        });
        this.accreditSection = acc;

        let res = await this.getAllDocumentsStates(this.allDocumentFields, this.evaluateCondition);
        if(res){
          this.allDocumentFields.forEach(f => {
            if(f.loadedState && f.loadedState.is_verified == verifiedEnumValue){
              //this.validatedDocuments.push(f.documentSet.id);
            }
          })
        }
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    async getFormInfoInterview(){
      try{
        let res1 = await axios.get("/api/v1/forms/getSectionFields/48");
        let url = this.ApiDomain + `/storagev3/documents/section/48`;
        let resd1 = await axios.get(url);
        let docStates = resd1.data;

        let acc = {data: res1.data.section, documentFields: []};

        acc.data.fields.forEach(f => {
          this.formFieldSetter(f, this);
          // check for documents
          if(f.doc_id != null){
            let ff = {formField: f, documentSet: null };
            if(docStates.length > 0){
              ff.documentSet = docStates.find(d => d.id == f.doc_id);
            }
            acc.documentFields.push(ff);
          }
        });
        this.interviewSection = acc;
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    async saveInterview(){
      let r = await this.$refs.interview.checkValidation();
      if(!r){
        this.missingFieldsNotif();
        return;
      }

      this.showLoading(true);
      let res = await this.$refs.interview.saveInterview(this.id);
      if(res == "ok"){
        this.saveSuccessNotif();
      }
      else {
        this.failedOperationNotif(null, null);
      }
      this.showLoading(false);
    },

    async requestAccreditation(){
      let ok  = await this.checkForValidAccreditation();
      if(!ok){
        return;
      }

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Solicitar acreditación de tecnología',
        text: `¿Estás seguro que deseas enviar tu solicitud a revisión? Una vez enviada no podrás modificar los datos`,
        acceptText: "Enviar solicitud",
        cancelText: 'Cancelar',
        accept: this.doRequestAccreditation
      });
    },

    async checkForValidAccreditation(){
      // comprobar que se cumplan todos los requerimientos
      this.totalInvalidFields = 0;
      this.docsMissing = [];
      this.testsMissing = [];
      this.questMissing = [];

      this.allDocumentFields.forEach(df => {
        if(!df.loadedState && df.documentSet.is_required == 1){
          if(df.formField.show_conditioned){
            let inc = this.evaluateCondition(df.formField.condition);
            if(inc != true){
              return;
            }
          }
          this.totalInvalidFields += 1;
          this.docsMissing.push({n: df.documentSet.name, e: "Documento requerido"} );
        }
        else if(df.loadedState && df.loadedState.is_verified == rejectedEnumValue){
          this.totalInvalidFields += 1;
          this.docsMissing.push({n: df.documentSet.name, e: "Documento en estado rechazado"} );
        }
      })

      try {
        let params = "?with[]=supplierFgTestimonies&with[]=supplierFgTestimonies.testimonyVerification&&filter[familyGroups.id]=" + this.family_group_id;
        let response = await axios.get(`/api/v1/supplier/${this.real_supplier_id}${params}`);
        let tests = response.data.supplier_fg_testimonies.filter(f => f.family_group_id == this.family_group_id);
        if(tests.length < 3){
          this.totalInvalidFields += 1;
          this.testsMissing.push({n: "Testimonios", e: "Debes agregar al menos 3 testimonios"} );
        }

        tests.forEach(t => {
          if(t.testimony_verification != null){
            if(t.testimony_verification.is_verified == rejectedEnumValue) {
              this.totalInvalidFields += 1;
              this.testsMissing.push({n: "Testimonios", e: `Ha sido rechazado el testimonio ${t.complete_name}, deberás eliminarlo y agregar uno nuevo.`} );
            }
          }
        });
      }
      catch (error) {
      }

      try {
        let res = await axios.get(`/api/v1/fg_survey/family_group/${this.family_group_id}/supplier/${this.real_supplier_id}/getSupplierInterviewResponses`);
        let supplierInterviewResponses = res.data;
        if(supplierInterviewResponses.length < 1){
          this.totalInvalidFields += 1;
          this.questMissing.push({n: "Cuestionario", e: "Debes responder el cuestionario"} );
        }
      }
      catch(error){
      }

      if(this.totalInvalidFields > 0){
        this.errorNotif("Campos pendientes",
          "Existen campos pendientes para realizar la solicitud. Por favor revisa las pestañas que indiquen un número de pendientes para ver el detalle.", 10000);
        return false;
      }

      return true;
    },
    onDocumentUploadSuccess(data){
      let d = this.allDocumentFields.find(f => f.documentSet.id == data.document_id);
      d.loadedState = data;
    },
    async doRequestAccreditation(){
      this.showLoading(true);
      try {
        let payload = { supplier_family_group_id: this.$props.id};
        let res = await axios.post(`/api/v1/supplier_family_group/requestTechnologyAccreditation`, payload);
        if(res.data == "success"){
          this.saveSuccessNotif();
          if(!this.ShowSectionToCompleteSupplierOnboarding) {
            this.$router.push({ name: 'perfil-editar' });
          } else {
            await this.$emit("updated", 1);
          }
          // await this.getSupplieFGData();
        }
      } catch (error) {
        this.warn(error);
        this.failedOperationNotif(null, null);
      }
      this.showLoading(false);
    },

    async requestPostRejectionEdit(){
      this.showLoading(true);
      try {
        let payload = { supplier_family_group_id: this.$props.id};
        let res = await axios.post(`/api/v1/supplier_family_group/requestTechnologyEdition`, payload);
        if(res.data == "success"){
          this.saveSuccessNotif();
          await this.getSupplieFGData();
        }
      } catch (error) {
        this.warn(error);
        this.failedOperationNotif(null, null);
      }
      this.showLoading(false);
    },

    async acceptAccreditation(){
      let ok  = await this.checkForValidAccreditation();
       if(!ok){
        return;
      }

      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: 'Aceptar acreditación de tecnología',
        text: `¿Estás seguro que deseas validar la solicitud? `,
        acceptText: "Validar solicitud",
        cancelText: 'Cancelar',
        accept: this.doAcceptAccreditation
      });
    },

    async rejectAccreditation(){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Rechazar la solicitud',
        text: `¿Estás seguro que deseas rechazar la solicitud? `,
        acceptText: "Rechazar solicitud",
        cancelText: 'Cancelar',
        accept: this.doRejectAccreditation
      });
    },

    async resetAccreditation(){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Regresar la solicitud a en proceso',
        text: `¿Estás seguro que deseas retornar el estado de la solicitud a En proceso de completar? El proveedor podrá modificar los datos de la misma y enviar la solicitud posteriormente `,
        acceptText: "Regresar estado de solicitud",
        cancelText: 'Cancelar',
        accept: this.requestPostRejectionEdit
      });
    },

    async requestEditAccreditation(){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Habilitar la solicitud para el proveedor',
        text: `¿Estás seguro que deseas habilitar de nueva cuenta la solicitud rechazada para el proveedor? `,
        acceptText: "Habilitar solicitud",
        cancelText: 'Cancelar',
        accept: this.requestPostRejectionEdit
      });
    },

    async doRejectAccreditation(){
      this.showLoading(true);
      try {
        let payload = { supplier_family_group_id: this.$props.id};
        let res = await axios.post(`/api/v1/supplier_family_group/rejectTechnologyAccreditation`, payload);
        if(res.data == "success"){
          this.saveSuccessNotif();
          await this.getSupplieFGData();
        }
      } catch (error) {
        this.warn(error);
        this.failedOperationNotif(null, null);
      }
      this.showLoading(false);
    },

    async doAcceptAccreditation(){
      this.showLoading(true);
      try {
        let payload = { supplier_family_group_id: this.$props.id};
        let res = await axios.post(`/api/v1/supplier_family_group/acceptTechnologyAccreditation`, payload);
        if(res.data == "success"){
          this.saveSuccessNotif();
          await this.getSupplieFGData();
        }
      } catch (error) {
        this.warn(error);
        this.failedOperationNotif(null, null);
      }
      this.showLoading(false);
    },

    /* on change receptor */
    onFieldChange(method){
      if(method != null && method in this){
        this[method]();
      }
    },

    /* obtener ids para los casos correspondientes */
    docTargetEvaluator(cls, cls_2){
      if(cls == 'user' && cls_2 == 'supplier'){
        return this.user_id;
      }
      return null;
    },

    /* evaluator */
    evaluateCondition(condition){
      return this.evaluateConditionBase(this, condition);
    },
  }
}
</script>

<style>
.big-title-card .rg-big-title {
    color: #3b3a3e !important;
}

.con-vs-dialog {
  z-index: 90000 !important;
}
</style>